.header-container__clz {
    background-color: rgb(26, 21, 21);
    display: flex;
    justify-content: space-between;
    padding-right: 59px;
    padding-left: 67px;
    opacity: 0.9;
    z-index: 3;
    position: relative;
}
